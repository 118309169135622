import React, { useState, useContext, useEffect } from 'react';
import classNames from 'classnames';
import { UserContext } from './UserContext';
import AppTopBar from './AppTopbar';
import { GateNowMap } from './pages/GateNowMap';
import { TabMenu } from 'primereact/tabmenu';
import { ConcessionsGateNow } from './pages/ConcessionsGateNow';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import './App.scss';

const App = () => {
  const [menuActive, setMenuActive] = useState(false);
  const [menuMode, setMenuMode] = useState('static');
  const [overlayMenuActive, setOverlayMenuActive] = useState(false);
  const [staticMenuDesktopInactive, setStaticMenuDesktopInactive] = useState(true);
  const [staticMenuMobileActive, setStaticMenuMobileActive] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const user = useContext(UserContext);
  const navigate = useNavigate();
  const location = useLocation();

  console.log("USER IN APP", user);

  const groupAccess = process.env.REACT_APP_GROUP_ACCESS?.split(",");
  const hasAccess = user?.userInfo?.groups?.some(group => groupAccess?.includes(group));

  const menu = [
    { label: 'Gates Now', icon: 'fa-solid fa-dungeon', to: '/concessionsgatenow' },
    { label: 'Map', icon: 'pi pi-fw pi-map', to: '/gatesnowmap' }
  ];

  const routers = [
    { path: '/', element: <ConcessionsGateNow />, exact: true },
    { path: '/concessionsgatenow', element: <ConcessionsGateNow /> },
    { path: '/gatesnowmap', element: <GateNowMap /> }
  ];

  // Create a mapping of paths to tab indices
  const pathToIndexMap = {
    '/': 0,
    '/concessionsgatenow': 0,
    '/gatesnowmap': 1,
  };

  useEffect(() => {
    const currentPath = location.pathname;
    const index = pathToIndexMap[currentPath] !== undefined ? pathToIndexMap[currentPath] : 0;
    setActiveIndex(index);
  }, [location]);

  const handleTabChange = (e) => {
    if (e.index !== activeIndex) {
      setActiveIndex(e.index);
      navigate(e.value.to);
    }
  };

  const onDocumentClick = () => {
    if (menuActive) setMenuActive(false);
  };

  const onMenuClick = () => setMenuActive(prev => !prev);
  let menuClassName = classNames('layout-menu-container');
  const isDesktop = () => window.innerWidth > 1024;

  const containerClassName = classNames('layout-wrapper', {
    'menu-layout-static': menuMode !== 'overlay',
    'menu-layout-overlay': menuMode === 'overlay',
    'layout-menu-overlay-active': overlayMenuActive,
    'menu-layout-slim': menuMode === 'slim',
    'menu-layout-horizontal': menuMode === 'horizontal',
    'layout-menu-static-inactive': staticMenuDesktopInactive,
    'layout-menu-static-active': staticMenuMobileActive,
  });

  return (
    <div className={containerClassName} onClick={onDocumentClick}>
      <AppTopBar 
        activeUser={user}
        profileMode="top"
        onMenuButtonClick={onMenuClick}
      />
      {hasAccess ? (
        <>
          <div className='menu'>
            <div className='col'>
              <TabMenu model={menu} activeIndex={activeIndex} onTabChange={handleTabChange} />
            </div>
          </div>
          
          <div className="layout-main">
            <Routes>
              {routers.map((router, index) => (
                <Route key={`router${index}`} path={router.path} element={router.element} />
              ))}
            </Routes>
          </div>
        </>
      ) : (
        <div className="layout-main" style={{ textAlign: "center", padding: "20px" }}>
          <h1 style={{ fontSize: "20px", fontWeight: "bold", color: "#333", marginTop: "100px" }}>
            Access Denied
          </h1>
          <p style={{ fontSize: "20px", color: "#555" }}>
            Please request access to the Data & Analytics team to proceed.
          </p>
        </div>
      )}
    </div>
  );
};

export default App;
